.notification {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
  max-width: 100%;
  position: relative;
}

.notification-info {
  background-color: #e3f2fd;
  border-left: 4px solid #2196f3;
}

.notification-success {
  background-color: #e8f5e9;
  border-left: 4px solid #4caf50;
}

.notification-warning {
  background-color: #fff8e1;
  border-left: 4px solid #ff9800;
}

.notification-error {
  background-color: #ffebee;
  border-left: 4px solid #f44336;
}

.notification-icon {
  margin-right: 12px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-message {
  flex: 1;
  font-size: 14px;
  line-height: 1.4;
}

.notification-close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 12px;
  padding: 0;
  color: #757575;
  opacity: 0.7;
}

.notification-close:hover {
  opacity: 1;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.notification.fade-out {
  animation: fadeOut 0.3s ease-out forwards;
}
