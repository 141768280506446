.card-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.card-popup {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  animation: slideIn 0.3s ease-out;
  position: relative;
  padding: 24px;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #718096;
  transition: all 0.2s ease;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
  background-color: rgba(247, 250, 252, 0.8);
  backdrop-filter: blur(2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.close-button:hover {
  background-color: #e2e8f0;
  color: #2d3748;
  transform: scale(1.05);
}

.close-icon {
  line-height: 1;
}

.card-popup-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.card-layout {
  display: flex;
  gap: 32px;
  background-color: #f8fafc;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
}

.card-left-column {
  flex: 0 0 auto;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-right-column {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-image-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.card-image {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card-image:hover {
  transform: scale(1.02);
}

.tcgplayer-link {
  display: flex;
  width: 100%;
  padding: 10px 16px;
  background-color: #f97316;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tcgplayer-link:hover {
  background-color: #ea580c;
}

.tcgplayer-link svg {
  width: 16px;
  height: 16px;
}

.card-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}

.card-name {
  font-size: 28px;
  color: #1a202c;
  margin: 0;
  line-height: 1.2;
  font-weight: 700;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 8px;
  text-align: center;
}

.card-details {
  background-color: #f7fafc;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.card-details p {
  margin: 0;
  color: #4a5568;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.card-details p strong {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 4px;
}

.card-details strong {
  color: #2d3748;
  margin-right: 8px;
}

.card-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.options-row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.options-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.options-group label {
  font-weight: 600;
  color: #2d3748;
  font-size: 14px;
}

.card-options select {
  padding: 10px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: white;
  color: #2d3748;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.card-options select:hover {
  border-color: #cbd5e0;
}

.card-options select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.price-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.price-input {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
}

.price-input-controls {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.slider-container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.slider {
  flex: 1;
}

.price-input label {
  font-weight: 600;
  color: #2d3748;
  font-size: 15px;
}

.price-input input[type="number"] {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 15px;
  color: #2d3748;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.price-input input[type="range"] {
  width: 100%;
  height: 6px;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(to right, #3182ce, #63b3ed);
  border-radius: 10px;
  outline: none;
  grid-column: 1 / 3;
}

.price-input input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: white;
  border: 2px solid #3182ce;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.price-input input[type="range"]::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background-color: white;
  border: 2px solid #3182ce;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.percentage-badge {
  color: #4a5568;
  font-size: 15px;
  font-weight: 600;
  background-color: #edf2f7;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: center;
  min-width: 80px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.sku-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Main tabs styling */
.main-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}

.main-tab-button {
  padding: 12px 24px;
  background: linear-gradient(to bottom, #f8fafc, #edf2f7);
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #4a5568;
  transition: all 0.2s ease;
  min-width: 140px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.main-tab-button.active {
  color: white;
  background: linear-gradient(135deg, #3182ce, #4299e1);
  border-color: #2b6cb0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.main-tab-button:hover:not(.active) {
  background: linear-gradient(to bottom, #edf2f7, #e2e8f0);
  transform: translateY(-1px);
}

/* Sub tabs styling */
.tab-navigation {
  margin: 0;
  padding: 16px;
  background-color: #f7fafc;
  color: #2d3748;
  font-size: 16px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tab-button {
  padding: 10px 20px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-weight: 600;
  color: #4a5568;
  transition: all 0.2s ease;
  margin-right: 12px;
  border-radius: 6px 6px 0 0;
}

.tab-button.active {
  color: #3182ce;
  border-bottom: 3px solid #3182ce;
  background-color: rgba(235, 248, 255, 0.5);
}

.tab-button:hover {
  color: #2c5282;
  background-color: rgba(235, 248, 255, 0.3);
}

.loading-indicator {
  margin-left: auto;
  font-size: 0.9em;
  color: #718096;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 6px;
}

.loading-indicator::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #cbd5e0;
  border-top-color: #3182ce;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.listings-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.listings-table th,
.listings-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.listings-table th {
  background-color: #f7fafc;
  font-weight: 600;
  color: #4a5568;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
}

.listings-table tr:hover {
  background-color: #f7fafc;
}

.no-listings-message {
  padding: 30px;
  text-align: center;
  color: #718096;
  font-style: italic;
  background-color: #f8fafc;
  border-radius: 8px;
  margin: 20px 0;
  border: 1px dashed #cbd5e0;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
  position: relative;
  padding: 16px;
  background-color: white;
  border-radius: 0 0 8px 8px;
}

.sku-list table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.sku-list th,
.sku-list td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
  font-size: 14px;
  white-space: nowrap;
}

.sku-list th {
  background-color: #f7fafc;
  font-weight: 600;
  color: #4a5568;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  position: sticky;
  top: 0;
  z-index: 1;
}

.sku-list tr:last-child td {
  border-bottom: none;
}

.sku-list tbody tr {
  transition: background-color 0.2s ease;
}

.sku-list tbody tr:hover {
  background-color: #f7fafc;
}

.price-cell {
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  min-width: 80px;
  text-align: right;
}

.price-cell.market {
  background-color: #ebf8ff;
  color: #2b6cb0;
}

.price-cell.low {
  background-color: #f0fff4;
  color: #2f855a;
}

.price-cell.mid {
  background-color: #fffaf0;
  color: #744210;
}

.price-cell.high {
  background-color: #fff5f5;
  color: #c53030;
}

.price-cell.list {
  background-color: #faf5ff;
  color: #6b46c1;
}

.price-cell.direct {
  background-color: #f0f5ff;
  color: #3182ce;
}

.col-group {
  border-right: 1px solid #e2e8f0;
  padding-right: 8px;
  margin-right: 8px;
}

.col-group:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

.price-type-header {
  font-size: 11px;
  color: #718096;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
}

.loading-indicator {
  font-size: 14px;
  color: #718096;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}

.loading-indicator::after {
  content: '';
  width: 12px;
  height: 12px;
  margin-left: 8px;
  border: 2px solid #e2e8f0;
  border-top-color: #4299e1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.sku-list th:nth-child(-n+3),
.sku-list td:nth-child(-n+3) {
  position: sticky;
  left: 0;
  background-color: inherit;
  z-index: 2;
}

.sku-list th:nth-child(-n+3) {
  background-color: #f7fafc;
}

.sku-list tr:hover td:nth-child(-n+3) {
  background-color: #f7fafc;
}

/* Trend prices tab styling */
.trend-prices-container {
  background-color: #f8fafc;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.trend-prices-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.trend-prices-header h3 {
  font-size: 18px;
  color: #2d3748;
  margin: 0;
}

.trend-prices-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.trend-prices-table th,
.trend-prices-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.trend-prices-table th {
  background-color: #f7fafc;
  font-weight: 600;
  color: #4a5568;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
}

.trend-prices-table tr:hover {
  background-color: #f7fafc;
}

/* Listings tab styling */
.listings-container {
  background-color: #f8fafc;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.listings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.listings-header h3 {
  font-size: 18px;
  color: #2d3748;
  margin: 0;
}

/* Selected row highlighting */
.selected-row {
  background-color: rgba(66, 153, 225, 0.1) !important;
  border-left: 3px solid #4299e1 !important;
  font-weight: 600;
}

.selected-row:hover {
  background-color: rgba(66, 153, 225, 0.15) !important;
}

.price-columns {
  display: flex;
  gap: 24px;
  padding: 16px;
  background-color: #f7fafc;
  border-bottom: 1px solid #e2e8f0;
}

.price-column {
  flex: 1;
}

.price-column-header {
  font-size: 12px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 4px;
}

.price-column-value {
  font-size: 16px;
  font-weight: 500;
  color: #2d3748;
}

.card-actions {
  display: flex;
  gap: 12px;
}

.add-to-cart-btn {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-width: 180px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-to-cart-btn.cash {
  background-color: #48bb78;
  color: white;
}

.add-to-cart-btn.cash:hover {
  background-color: #38a169;
}

.add-to-cart-btn.credit {
  background-color: #4299e1;
  color: white;
}

.add-to-cart-btn.credit:hover {
  background-color: #3182ce;
}

@media (max-width: 1024px) {
  .card-layout {
    flex-direction: column;
    gap: 24px;
  }

  .card-left-column {
    width: 100%;
  }

  .card-image-container {
    max-width: 250px;
    margin: 0 auto;
  }

  .options-row {
    flex-direction: column;
    gap: 16px;
  }

  .slider-container {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }

  .percentage-badge {
    align-self: center;
  }

  .add-to-cart-btn {
    width: 100%;
  }
}
