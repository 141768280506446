.kiosk-features {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f6f8fa, #ffffff);
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -5px rgba(0, 0, 0, 0.04);
}

.psa-icon {
  background-color: #ffc107;
  color: white;
}

.grade-icon {
  background: linear-gradient(135deg, #f59e0b, #fbbf24);
  color: white;
  box-shadow: 0 4px 6px -1px rgba(245, 158, 11, 0.3);
}

.quote-icon {
  background: linear-gradient(135deg, #0ea5e9, #38bdf8);
  color: white;
  box-shadow: 0 4px 6px -1px rgba(14, 165, 233, 0.3);
}

.pos-icon {
  background: linear-gradient(135deg, #16a34a, #22c55e);
  color: white;
  box-shadow: 0 4px 6px -1px rgba(22, 163, 74, 0.3);
}

.event-icon {
  background: linear-gradient(135deg, #8b5cf6, #a78bfa);
  color: white;
  box-shadow: 0 4px 6px -1px rgba(139, 92, 246, 0.3);
}

.features-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 1.5rem;
  text-align: center;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.feature-card {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.feature-card:hover .feature-arrow {
  opacity: 1;
  transform: translateX(0);
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 1.25rem;
  flex-shrink: 0;
}

.csv-icon {
  background: linear-gradient(135deg, #4f46e5, #3b82f6);
  color: white;
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.3);
}

.scanner-icon {
  background: linear-gradient(135deg, #10b981, #3b82f6);
  color: white;
  box-shadow: 0 4px 6px -1px rgba(16, 185, 129, 0.3);
}

.feature-content {
  flex: 1;
}

.feature-content h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 0.5rem 0;
}

.feature-content p {
  font-size: 0.95rem;
  color: #6b7280;
  margin: 0;
}

.feature-arrow {
  position: absolute;
  right: 1.5rem;
  color: #4b5563;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.features-help {
  background-color: #f3f4f6;
  padding: 1.25rem;
  border-radius: 0.75rem;
  text-align: center;
}

.features-help p {
  font-size: 1rem;
  color: #4b5563;
  margin: 0;
}

.active-feature-container {
  position: relative;
}

.back-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  background-color: #f3f4f6;
  color: #4b5563;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  background-color: #e5e7eb;
  color: #1f2937;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.feature-card {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.feature-card:nth-child(1) { animation-delay: 0.1s; }
.feature-card:nth-child(2) { animation-delay: 0.2s; }

.features-help {
  animation: fadeIn 0.5s ease-out 0.3s forwards;
  opacity: 0;
}

@media (max-width: 768px) {
  .kiosk-features {
    padding: 1.5rem;
  }
  
  .features-title {
    font-size: 1.5rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .feature-card {
    padding: 1.25rem;
  }
  
  .feature-icon {
    width: 56px;
    height: 56px;
  }
  
  .feature-content h3 {
    font-size: 1.1rem;
  }
  
  .feature-content p {
    font-size: 0.9rem;
  }
}
