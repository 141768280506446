/* Grid container styling */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 28px;
  margin-top: 20px;
  padding: 10px;
}

.card-grid-item {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  transform: translateY(0);
  background-image: linear-gradient(to bottom, #ffffff, #f9fafb);
}

.card-grid-item:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.08);
  transform: translateY(-5px);
}

.card-image {
  height: 280px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  border-bottom: none;
  padding: 12px;
  box-sizing: border-box;
}

.card-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  display: block;
  margin: 0 auto;
}

.card-grid-item:hover .card-image img {
  transform: scale(1.03);
}

.card-details {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid rgba(230, 235, 240, 0.5);
  margin-top: 8px;
}

.card-name {
  font-size: 15px;
  font-weight: 700;
  margin: 0 0 6px 0;
  color: #1a202c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  height: 2.8em;
  word-wrap: break-word;
  letter-spacing: -0.01em;
}

.card-set {
  font-size: 13px;
  color: #4a5568;
  margin: 0 0 6px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.card-rarity {
  font-size: 12px;
  color: #805ad5;
  font-weight: 600;
  margin: 0 0 6px 0;
  display: inline-block;
  padding: 2px 8px;
  background-color: #f8f4ff;
  border-radius: 12px;
}

.card-id {
  font-size: 12px;
  color: #888;
  margin-bottom: 10px;
}

.card-selects {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
  margin-top: 6px;
}

.card-selects select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 13px;
  background-color: #fff;
  color: #2d3748;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%234a5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.card-selects select:hover {
  border-color: #cbd5e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.card-selects select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.card-prices {
  margin-bottom: 16px;
  padding: 12px;
  background-color: #f7fafc;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.price-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 13px;
  padding: 4px 0;
}

.price-row:last-child {
  margin-bottom: 0;
}

.price-row span:first-child {
  color: #4a5568;
  font-weight: 500;
}

.price-row span:last-child {
  font-weight: 700;
  color: #2d3748;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: -0.02em;
}

.in-stock {
  color: #38a169 !important; /* Green */
  font-weight: 700 !important;
  background-color: rgba(56, 161, 105, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
}

.low-stock {
  color: #dd6b20 !important; /* Amber/Orange */
  font-weight: 700 !important;
  background-color: rgba(221, 107, 32, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
}

.out-of-stock {
  color: #e53e3e !important; /* Red */
  font-weight: 700 !important;
  background-color: rgba(229, 62, 62, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
}

.card-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}

.add-to-cart-btn {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-radius: 24px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 0.03em;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-to-cart-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.4s ease;
}

.add-to-cart-btn:hover::before {
  left: 100%;
}

.add-to-cart-btn.cash {
  background: linear-gradient(135deg, #48bb78, #2f855a);
  color: white;
}

.add-to-cart-btn.cash:hover {
  background: linear-gradient(135deg, #38a169, #276749);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.add-to-cart-btn.cash:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-to-cart-btn.credit {
  background: linear-gradient(135deg, #4299e1, #2b6cb0);
  color: white;
}

.add-to-cart-btn.credit:hover {
  background: linear-gradient(135deg, #3182ce, #2c5282);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.add-to-cart-btn.credit:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.price-timestamp {
  font-size: 11px;
  color: #a0aec0;
  text-align: center;
  margin-top: 12px;
  font-style: italic;
}

.warning-card-severe {
  position: relative;
}

.warning-card-severe::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(to bottom, #f56565, #c53030);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.warning-card-moderate {
  position: relative;
}

.warning-card-moderate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(to bottom, #ed8936, #dd6b20);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
  }
  
  .card-grid-item {
    max-width: 220px;
  }
  
  .card-image {
    height: 240px;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }
  
  .card-grid-item {
    max-width: 200px;
  }
  
  .card-image {
    height: 220px;
    padding: 10px;
  }
  
  .card-details {
    padding: 10px;
  }
  
  .card-name {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 12px;
  }
  
  .card-grid-item {
    max-width: 160px;
  }
  
  .card-image {
    height: 200px;
    padding: 8px;
  }
  
  .card-details {
    padding: 8px;
  }
  
  .card-name {
    font-size: 13px;
  }
  
  .card-set {
    font-size: 12px;
  }
  
  .add-to-cart-btn {
    font-size: 12px;
    padding: 6px 0;
  }
}

/* Animation for card appearance */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-grid-item {
  animation: fadeInUp 0.4s ease-out forwards;
}

/* Add staggered animation delay for cards */
.grid-container .card-grid-item:nth-child(1) { animation-delay: 0.05s; }
.grid-container .card-grid-item:nth-child(2) { animation-delay: 0.1s; }
.grid-container .card-grid-item:nth-child(3) { animation-delay: 0.15s; }
.grid-container .card-grid-item:nth-child(4) { animation-delay: 0.2s; }
.grid-container .card-grid-item:nth-child(5) { animation-delay: 0.25s; }
.grid-container .card-grid-item:nth-child(6) { animation-delay: 0.3s; }
.grid-container .card-grid-item:nth-child(7) { animation-delay: 0.35s; }
.grid-container .card-grid-item:nth-child(8) { animation-delay: 0.4s; }
.grid-container .card-grid-item:nth-child(9) { animation-delay: 0.45s; }
.grid-container .card-grid-item:nth-child(10) { animation-delay: 0.5s; }
