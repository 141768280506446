.card-scanner-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -5px rgba(0, 0, 0, 0.04);
}

.scanner-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

.scanner-content {
  margin-bottom: 1.5rem;
  border-radius: 0.75rem;
  overflow: hidden;
  background-color: #f3f4f6;
}

.camera-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.camera-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.scan-area {
  width: 80%;
  height: 80%;
  border: 2px solid rgba(76, 175, 80, 0.7);
  border-radius: 8px;
  box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.3);
}

.camera-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.capture-button, 
.cancel-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.capture-button {
  background-color: #4CAF50;
  color: white;
}

.capture-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: rgba(255, 255, 255, 0.8);
  color: #1f2937;
}

.cancel-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.scanner-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  text-align: center;
}

.placeholder-icon {
  color: #9ca3af;
  margin-bottom: 1.5rem;
}

.placeholder-text {
  font-size: 1.1rem;
  color: #4b5563;
  margin-bottom: 2rem;
  max-width: 500px;
}

.start-scan-button {
  padding: 0.875rem 1.75rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-scan-button:hover {
  background-color: #45a049;
}

.scanner-error {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #fee2e2;
  color: #b91c1c;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.scanner-instructions {
  background-color: #f3f4f6;
  padding: 1.25rem;
  border-radius: 0.75rem;
}

.scanner-instructions h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 0.75rem 0;
}

.scanner-instructions ol {
  margin: 0;
  padding-left: 1.5rem;
}

.scanner-instructions li {
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.scan-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.success-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #d1fae5;
  color: #065f46;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.scan-result h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 1rem 0;
}

.card-details {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 400px;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-details p {
  margin: 0.5rem 0;
  font-size: 0.95rem;
  color: #4b5563;
}

.scan-again-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scan-again-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .card-scanner-container {
    padding: 1.5rem;
  }
  
  .scanner-title {
    font-size: 1.25rem;
  }
  
  .placeholder-text {
    font-size: 1rem;
  }
  
  .camera-controls {
    padding: 0.75rem;
  }
  
  .capture-button, 
  .cancel-button {
    padding: 0.625rem 1.25rem;
    font-size: 0.9rem;
  }
}
