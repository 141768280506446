.csv-import-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -5px rgba(0, 0, 0, 0.04);
}

.csv-import-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

.csv-import-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  border: 2px dashed #d1d5db;
  border-radius: 0.75rem;
  background-color: #f9fafb;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.csv-import-dropzone:hover {
  border-color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.05);
}

.csv-import-dropzone.has-file {
  border-color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.upload-icon {
  color: #9ca3af;
  margin-bottom: 1rem;
}

.csv-import-dropzone.has-file .upload-icon {
  color: #4CAF50;
}

.dropzone-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: #4b5563;
  margin: 0 0 0.5rem 0;
}

.dropzone-subtext {
  font-size: 0.9rem;
  color: #6b7280;
  margin: 0 0 1rem 0;
}

.file-selected {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: #4CAF50;
  font-weight: 500;
  margin: 0 0 1rem 0;
}

.check-icon {
  color: #4CAF50;
}

.file-input {
  display: none;
}

.file-input-label {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-input-label:hover {
  background-color: #45a049;
}

.error-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #fee2e2;
  color: #b91c1c;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.csv-format-info {
  background-color: #f3f4f6;
  padding: 1.25rem;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
}

.csv-format-info h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 0.75rem 0;
}

.csv-format-info p {
  font-size: 0.95rem;
  color: #4b5563;
  margin: 0 0 0.75rem 0;
}

.csv-format-info ul {
  margin: 0;
  padding-left: 1.5rem;
}

.csv-format-info li {
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.upload-button {
  display: block;
  width: 100%;
  padding: 0.875rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover:not(.disabled) {
  background-color: #45a049;
}

.upload-button.disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.success-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #d1fae5;
  color: #065f46;
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .csv-import-container {
    padding: 1.5rem;
  }
  
  .csv-import-dropzone {
    padding: 1.5rem;
  }
  
  .csv-import-title {
    font-size: 1.25rem;
  }
  
  .dropzone-text {
    font-size: 1rem;
  }
}
