.card-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.view-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.view-toggle button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-toggle button.active {
  background-color: #4CAF50;
  color: white;
}

.view-toggle button:hover {
  background-color: #ddd;
}

.view-toggle button.active:hover {
  background-color: #45a049;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  width: 100%;
  max-width: 250px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 0 auto;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card.bulk-item {
  border: 2px solid #FFA500;
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 10px auto;
}

.card-info {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-name {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 10px 0;
  min-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-expansion {
  font-size: 12px;
  color: #666;
  margin: 0 0 8px 0;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 15px;
}

.condition-select,
.printing-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.condition-select:focus,
.printing-select:focus {
  outline: none;
  border-color: #2196F3;
}

.card-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
  width: 100%;
}

.add-to-cart-button {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  box-sizing: border-box;
}

.add-to-cart-button:active {
  transform: scale(0.98);
}

.add-to-cart-button.cash {
  background-color: #4CAF50;
}

.add-to-cart-button.credit {
  background-color: #2196F3;
}

.add-to-cart-button:hover {
  opacity: 0.9;
}

.add-to-cart-button.bulk {
  background-color: #FFA500;
  color: #fff;
}

.add-to-cart-button.not-buying {
  background-color: #ffcccc;
  color: #888;
  cursor: not-allowed;
}

.add-to-cart-button.not-buying:hover {
  opacity: 1;
}

.price-timestamp {
  font-size: 10px;
  color: #888;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.price-timestamp:hover {
  color: #2196F3;
  text-decoration: underline;
}

.bulk-notice {
  font-size: 12px;
  color: #FFA500;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}

.price-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.price-slider label {
  font-size: 12px;
  margin-bottom: 5px;
  color: #333;
}

.price-slider input[type="range"] {
  width: 100%;
  margin: 5px 0;
  -webkit-appearance: none;
  background: transparent;
}

.price-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
  margin-top: -5px;
}

.price-slider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #ddd;
  border-radius: 3px;
}

.price-slider input[type="range"]:focus {
  outline: none;
}

.price-slider input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

.price-slider span {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.price-slider .add-to-cart-button {
  margin-top: 5px;
}

.card-number {
  font-size: 0.8em;
  color: #666;
  margin-top: 2px;
}

@media (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .card {
    max-width: 100%;
  }

  .card-name {
    font-size: 12px;
    min-height: 36px;
  }

  .add-to-cart-button {
    font-size: 10px;
    padding: 6px;
  }
}
