body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.app {
  display: flex;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

/* Feature buttons styling */
.feature-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.feature-button {
  padding: 8px 12px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s ease;
  flex: 1;
  min-width: 100px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Added for badge positioning */
}

/* Coming Soon Badge */
.coming-soon-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: linear-gradient(135deg, #f56565, #e53e3e);
  color: white;
  font-size: 8px;
  font-weight: bold;
  padding: 4px 6px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: rotate(5deg);
  z-index: 1;
}

.feature-button:nth-child(1) {
  background: linear-gradient(135deg, #4f46e5, #3b82f6);
}

.feature-button:nth-child(2) {
  background: linear-gradient(135deg, #10b981, #3b82f6);
}

.feature-button:nth-child(3) {
  background-color: #ffc107;
}

.feature-button:nth-child(4) {
  background-color: #6f42c1;
}

.feature-button:nth-child(5) {
  background: linear-gradient(135deg, #e53e3e, #f56565);
}

.feature-button:nth-child(6) {
  background: linear-gradient(135deg, #f59e0b, #fbbf24);
}

.feature-button:nth-child(7) {
  background: linear-gradient(135deg, #0ea5e9, #38bdf8);
}

.feature-button:nth-child(8) {
  background: linear-gradient(135deg, #16a34a, #22c55e);
}

.feature-button:nth-child(9) {
  background: linear-gradient(135deg, #8b5cf6, #a78bfa);
}

.feature-button:nth-child(10) {
  background: linear-gradient(135deg, #ec4899, #f472b6);
}

.feature-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.main-content {
  flex: 0 0 72%; /* Increased to complement the cart's 28% */
  padding: 20px;
  background-color: #f9f9f9;
}

.cart-container {
  flex: 0 0 28%; /* Reduced by 20% from 35% */
  padding: 20px 20px 20px 20px; /* Equal padding on all sides */
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Add scroll if content overflows */
  max-height: 100vh; /* Limit height to viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  position: sticky; /* Make the cart sticky */
  top: 0; /* Stick to the top of the viewport */
  align-self: flex-start; /* Ensure it sticks to the top */
  height: 100vh; /* Set height to viewport height */
}

.business-name-header {
  font-size: 28px;
  font-weight: 700;
  color: #4CAF50;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  border-bottom: 3px solid #4CAF50;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-name-display {
  font-size: 24px;
  font-weight: 700;
  color: #4CAF50;
  margin-bottom: 20px;
  padding: 10px 15px;
  text-align: center;
  border-bottom: 2px solid #4CAF50;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.settings-icon-wrapper {
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #3b82f6, #1e40af);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.search-and-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.search-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.search-and-filter-container {
  display: flex;
  gap: 10px;
  width: 100%;
}

.search-input,
.number-filter-input {
  flex-grow: 1;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-input {
  flex-basis: 70%;
}

.number-filter-input {
  flex-basis: 30%;
}

.search-input:focus,
.number-filter-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
}

.search-button {
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #45a049;
}

.search-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  height: 38px; /* Match the height of the input */
}

.search-button:hover {
  background-color: #45a049;
}

.filter-select {
  flex: 1 1 calc(50% - 5px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 120px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
}

@media (max-width: 1200px) {
  .app {
    flex-direction: column;
  }

  .main-content {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
    padding-bottom: 60px; /* Add padding to prevent content from being hidden behind fixed cart */
  }

  .cart-container {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
    position: fixed; /* Change to fixed on mobile */
    bottom: 0; /* Position at bottom of screen */
    top: auto; /* Override top: 0 */
    left: 0;
    right: 0;
    height: auto; /* Auto height */
    max-height: 50vh; /* Limit to half the viewport height */
    z-index: 1000; /* Ensure cart appears above other content */
    border-top: 1px solid #ddd; /* Add border to separate from content */
  }

  .filters {
    flex-direction: column;
  }

  .search-and-upload {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .filter-select {
    flex-basis: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .filters {
    flex-direction: row;
  }

  .filter-select {
    flex: 1 1 calc(33.33% - 10px);
  }
}

/* New styles for CORS error message */
.cors-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.cors-error h2 {
  color: #d32f2f;
  margin-bottom: 20px;
}

.cors-error p {
  margin-bottom: 15px;
  max-width: 600px;
}

.cors-button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.cors-button:hover {
  background-color: #45a049;
}

/* Settings icon styling */
.settings-icon {
  position: absolute;
  top: 25px; /* Align with business name text */
  right: 40px;
  background: linear-gradient(135deg, #3b82f6, #1e40af);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 100;
}

.settings-icon:hover {
  transform: rotate(30deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* View toggle button styling */
.view-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.view-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.view-toggle-button.active {
  background: linear-gradient(135deg, #3b82f6, #1e40af);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.view-toggle-button:hover:not(.active) {
  background-color: #f3f4f6;
}

/* Quote form styles */
.quote-form-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -5px rgba(0, 0, 0, 0.04);
  text-align: center;
}

.quote-form-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #1f2937;
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quote-form label {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: 600;
  color: #374151;
}

.quote-form input[type="text"],
.quote-form input[type="email"] {
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 8px;
}

.quote-form input[type="text"]:focus,
.quote-form input[type="email"]:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.3);
}

.quote-form button {
  padding: 12px 20px;
  background: linear-gradient(135deg, #4f46e5, #3b82f6);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.quote-form button:hover {
  background: linear-gradient(135deg, #4338ca, #2563eb);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}
