.table-responsive {
  overflow-x: auto;
  margin-bottom: 1.5rem;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.warning-legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 12px 16px;
  background-color: #f8fafc;
  border-radius: 8px;
  font-size: 0.9em;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.warning-legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.warning-legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  flex-shrink: 0;
}

.warning-legend-color.severe {
  background-color: #f56565;
}

.warning-legend-color.moderate {
  background-color: #ecc94b;
}

.card-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.card-table th,
.card-table td {
  padding: 16px 18px;
  text-align: left;
  border-bottom: 1px solid #edf2f7;
}

.card-table th {
  background-color: #f8fafc;
  font-weight: 600;
  color: #2d3748;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.card-table td {
  font-size: 0.95em;
  color: #4a5568;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

/* Ensure proper alignment for button cells */
.card-table tr td:nth-child(8),
.card-table tr td:nth-child(9) {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.card-table tbody tr {
  cursor: pointer;
  transition: all 0.2s ease;
}

.card-table tbody tr:hover {
  background-color: #f7fafc;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.card-table tbody tr:active {
  transform: translateY(0);
  box-shadow: none;
}

.card-table tbody tr:last-child td {
  border-bottom: none;
}

.card-table select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 0.9em;
  color: #4a5568;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%234a5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.card-table select:hover {
  border-color: #cbd5e0;
}

.card-table select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

/* Make printing select wider */
.card-table tr th:nth-child(5),
.card-table tr td:nth-child(5) {
  min-width: 150px;
  width: auto;
}

/* Make cash/credit offer columns appropriate width */
.card-table tr th:nth-child(8),
.card-table tr th:nth-child(9),
.card-table tr td:nth-child(8),
.card-table tr td:nth-child(9) {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  padding-left: 12px;
  padding-right: 12px;
}

.add-to-cart-btn {
  width: 90%;
  padding: 10px 8px;
  border: none;
  border-radius: 24px;
  color: #fff;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  display: block;
  background-color: #4299e1;
  letter-spacing: 0.02em;
}

.add-to-cart-btn.cash {
  background: linear-gradient(135deg, #48bb78, #2f855a);
  border-radius: 24px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.12);
}

.add-to-cart-btn.credit {
  background: linear-gradient(135deg, #4299e1, #2b6cb0);
  border-radius: 24px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.12);
}

.add-to-cart-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.4s ease;
}

.add-to-cart-btn:hover::before {
  left: 0%;
}

.add-to-cart-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.add-to-cart-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

/* Hover image styles removed */
.card-table tbody tr {
  position: relative;
}

.card-table td {
  position: relative;
}

/* Warning styles */
.warning-row-severe {
  background-color: rgba(254, 226, 226, 0.6) !important;
  border-left: 3px solid #f56565 !important;
}

.warning-row-severe:hover {
  background-color: rgba(254, 215, 215, 0.7) !important;
}

.warning-row-moderate {
  background-color: rgba(254, 252, 232, 0.6) !important;
  border-left: 3px solid #ecc94b !important;
}

.warning-row-moderate:hover {
  background-color: rgba(254, 250, 212, 0.7) !important;
}

@media screen and (max-width: 1024px) {
  .card-table th {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

@media screen and (max-width: 768px) {
  .card-table {
    font-size: 0.9em;
  }

  .card-table th,
  .card-table td {
    padding: 10px 12px;
  }

  /* Adjust column widths for tablets */
  .card-table tr th:nth-child(5),
  .card-table tr td:nth-child(5) {
    min-width: 120px;
  }

  .card-table tr th:nth-child(8),
  .card-table tr th:nth-child(9),
  .card-table tr td:nth-child(8),
  .card-table tr td:nth-child(9) {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }
}

@media screen and (max-width: 600px) {
  .card-table {
    font-size: 0.8em;
  }

  .card-table th,
  .card-table td {
    padding: 8px 10px;
  }

  /* Further adjust column widths for mobile */
  .card-table tr th:nth-child(5),
  .card-table tr td:nth-child(5) {
    min-width: 100px;
  }

  .card-table tr th:nth-child(8),
  .card-table tr th:nth-child(9),
  .card-table tr td:nth-child(8),
  .card-table tr td:nth-child(9) {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }

  .add-to-cart-btn {
    padding: 8px 6px;
    font-size: 0.8em;
    width: 95%;
  }

  /* Hover image styles removed */

  .warning-legend {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
