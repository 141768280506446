.cart {
  background: linear-gradient(145deg, #f8f9fa, #ffffff);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  max-width: 100%;
  width: 85%; /* Further reduced width to prevent overflow */
  margin: 0 auto; /* Center the cart in its container */
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  align-self: center; /* Center in flex container */
}

.customer-info-panel {
  background: linear-gradient(145deg, #e6f7ff, #f0f9ff);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(66, 153, 225, 0.2);
  position: relative;
  overflow: hidden;
}

.customer-info-panel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(to bottom, #4299e1, #2b6cb0);
}

.customer-info-panel h3 {
  font-size: 18px;
  color: #2c5282;
  margin: 0 0 12px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.customer-info-panel h3::before {
  content: '👤';
  margin-right: 8px;
  font-size: 20px;
}

.customer-info-panel p {
  margin: 8px 0;
  color: #4a5568;
  font-size: 14px;
  line-height: 1.5;
}

.customer-info-panel strong {
  color: #2d3748;
  font-weight: 600;
}

.notification {
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  animation: slideIn 0.3s ease-out forwards;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.notification-success {
  background-color: #f0fff4;
  border-left: 4px solid #48bb78;
  color: #2f855a;
}

.notification-info {
  background-color: #ebf8ff;
  border-left: 4px solid #4299e1;
  color: #2b6cb0;
}

.notification-warning {
  background-color: #fffaf0;
  border-left: 4px solid #ed8936;
  color: #c05621;
}

.notification-error {
  background-color: #fff5f5;
  border-left: 4px solid #f56565;
  color: #c53030;
}

.notification-icon {
  margin-right: 12px;
  font-size: 18px;
}

.notification-message {
  flex: 1;
}

.notification-close {
  background: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.notification-close:hover {
  opacity: 1;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.item-condition,
.item-printing {
  display: inline-block;
  font-size: 13px;
  color: #6c757d;
  margin-top: 2px;
}

.item-condition {
  margin-right: 12px;
}

.cart-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
}

.add-custom-item {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.add-custom-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
  font-weight: 600;
}

.add-custom-item form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.add-custom-item input,
.add-custom-item select {
  padding: 10px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.add-custom-item input:focus,
.add-custom-item select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.price-input-group {
  display: flex;
  gap: 12px;
}

.price-input-group input {
  flex: 2;
}

.price-input-group select {
  flex: 1;
}

.add-custom-item-btn {
  background: linear-gradient(135deg, #4299e1, #2b6cb0);
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 14px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.12);
  position: relative;
  overflow: hidden;
  letter-spacing: 0.02em;
}

.add-custom-item-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.4s ease;
}

.add-custom-item-btn:hover::before {
  left: 100%;
}

.add-custom-item-btn:hover {
  transform: translateY(-1px);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 6px 12px rgba(0,0,0,0.15);
  background: linear-gradient(135deg, #3182ce, #2c5282);
}

.cart-empty {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 30px 0;
  font-size: 16px;
}

.cart-items-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 5px;
}

.cart-items-container::-webkit-scrollbar {
  width: 6px;
}

.cart-items-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.cart-items-container::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

.cart-items-container::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.cart-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cart-item {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.cart-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.item-details {
  flex: 1 1 100%;
  margin-bottom: 12px;
}

.item-name {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 6px;
  display: block;
  font-size: 15px;
}

.item-price {
  color: #38a169;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease;
}

.item-price:hover {
  color: #2f855a;
  text-decoration: underline;
}

.edit-price-hint {
  font-size: 12px;
  color: #718096;
  margin-left: 5px;
}

.price-edit {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.price-edit input {
  width: 90px;
  padding: 8px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.price-edit input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.price-edit button {
  background: linear-gradient(135deg, #48bb78, #2f855a);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
}

.price-edit button:hover {
  transform: translateY(-1px);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.15);
}

.item-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.quantity-payment-wrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f7fafc;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.quantity-button {
  background: linear-gradient(145deg, #f8f9fa, #e2e8f0);
  border: none;
  color: #4a5568;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.quantity-button:hover {
  background: linear-gradient(145deg, #e2e8f0, #cbd5e0);
  color: #2d3748;
}

.quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.item-quantity {
  font-weight: 600;
  color: #2d3748;
  min-width: 20px;
  text-align: center;
}

.toggle-payment-button {
  background: linear-gradient(135deg, #4299e1, #2b6cb0);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.toggle-payment-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.4s ease;
}

.toggle-payment-button:hover::before {
  left: 100%;
}

.toggle-payment-button:hover {
  transform: translateY(-1px);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.15);
}

.remove-button {
  background: linear-gradient(135deg, #f56565, #c53030);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.remove-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.4s ease;
}

.remove-button:hover::before {
  left: 100%;
}

.remove-button:hover {
  transform: translateY(-1px);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.15);
}

.cart-summary {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.subtotal,
.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0;
}

.total {
  font-weight: 700;
  font-size: 18px;
  border-top: 1px solid #e2e8f0;
  padding-top: 12px;
  margin-top: 5px;
  color: #2d3748;
}

.cart-actions {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.checkout-button,
.clear-cart-button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.02em;
}

.checkout-button {
  background: linear-gradient(135deg, #48bb78, #2f855a);
  color: #fff;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.12);
}

.checkout-button::before,
.clear-cart-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.4s ease;
}

.checkout-button:hover::before,
.clear-cart-button:hover::before {
  left: 100%;
}

.checkout-button:hover {
  transform: translateY(-2px);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 6px 12px rgba(0,0,0,0.15);
  background: linear-gradient(135deg, #38a169, #276749);
}

.clear-cart-button {
  background: linear-gradient(135deg, #718096, #4a5568);
  color: #fff;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 4px 8px rgba(0,0,0,0.12);
}

.clear-cart-button:hover {
  transform: translateY(-2px);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 6px 12px rgba(0,0,0,0.15);
  background: linear-gradient(135deg, #4a5568, #2d3748);
}

@media (max-width: 640px) {
  .cart {
    width: 100%;
    padding: 16px;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .quantity-payment-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  .remove-button {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .cart-actions {
    flex-direction: column;
  }

  .checkout-button,
  .clear-cart-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
