.checkout-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 12px;
}

.checkout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.checkout-header h1 {
  font-size: 32px;
  font-weight: 700;
  color: #2c3e50;
  margin: 0;
}

.back-button {
  background-color: #f1f3f5;
  border: none;
  padding: 12px 18px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #495057;
  font-weight: 500;
}

.back-button:hover {
  background-color: #e9ecef;
  color: #212529;
  transform: translateX(-3px);
}

.back-arrow {
  margin-right: 10px;
  font-weight: bold;
}

.checkout-content {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.checkout-form {
  flex: 1;
  min-width: 300px;
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
}

.customer-info-panel {
  background-color: #f1f8fe;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  border-left: 4px solid #3498db;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.customer-info-panel h3 {
  margin-top: 0;
  color: #3498db;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.customer-info-panel p {
  margin: 10px 0;
  line-height: 1.6;
  color: #444;
  font-size: 15px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #2c3e50;
  font-size: 16px;
}

.form-group input[type="text"],
.form-group input[type="email"] {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  background-color: #f9f9f9;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.15);
  background-color: #fff;
}

.customer-search-form {
  display: flex;
  gap: 12px;
  margin-bottom: 5px;
}

.customer-search-form input {
  flex: 1;
  padding: 14px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  background-color: #f9f9f9;
}

.customer-search-form input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.15);
  background-color: #fff;
}

.customer-search-form button {
  padding: 14px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.customer-search-form button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.customer-search-form button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
  color: #333;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #3498db;
}

.button-group {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 35px;
}

.submit-button {
  background-color: #27ae60;
  color: white;
  padding: 16px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  transition: all 0.2s ease;
  min-width: 160px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.submit-button:hover {
  background-color: #219653;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.submit-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.leads-online-button {
  background-color: #3498db;
  color: white;
  padding: 16px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  transition: all 0.2s ease;
  min-width: 160px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.leads-online-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.leads-online-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.order-summary {
  flex: 1;
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  min-width: 300px;
}

.order-summary h2 {
  color: #2c3e50;
  font-size: 28px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f39c12;
}

.order-total {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  text-align: right;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #2c3e50;
  border-right: 4px solid #f39c12;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.order-items {
  margin-top: 20px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.order-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.item-details {
  flex: 2;
  padding: 0 10px;
}

.item-name {
  font-weight: 700;
  font-size: 17px;
  color: #2c3e50;
  margin-bottom: 5px;
  display: block;
}

.item-info {
  font-size: 14px;
  color: #7f8c8d;
  display: block;
}

.item-price {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  padding: 0 10px;
}

.cash-price {
  color: #27ae60;
}

.credit-price {
  color: #3498db;
}

.item-quantity {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.item-quantity span {
  font-weight: 600;
  font-size: 16px;
  min-width: 25px;
  text-align: center;
}

.item-actions {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.quantity-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f5;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #495057;
}

.quantity-btn:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.toggle-btn {
  background-color: #f39c12;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-btn:hover {
  background-color: #e67e22;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.remove-btn {
  background-color: #e74c3c;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.remove-btn:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.error {
  color: #f44336;
  margin-bottom: 15px;
}
